import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"
import CategoryList from "../components/categoryList"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Nav/>
    <CategoryList/>

  </Layout>
)

export default IndexPage
