import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import CategoryDetail from "./categoryDetail"
import { Grid, Paper } from "@material-ui/core"

//filter: {isShown: {eq: true}, post: {elemMatch: {isShown: {eq: true}}}}  
    


const CategoryList = () => {
  const query = graphql`
  query CategoryListQuery {
    allContentfulCategory(
      sort: {order: ASC, fields: sortOrder}
      filter: {isShown: {eq: true}}  
    ) {
      edges {
        node {
          id
          title
          slug
          description {
            childMarkdownRemark {
              html
            }
          }
          post {
            title
            id
            slug
            isShown
            postBody {
              childMarkdownRemark {
                html
              }
            }
            excerpt {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
  `

  const data = useStaticQuery(query);

  const list = data.allContentfulCategory.edges;

  return (
      <Grid container style={{flexGrow: 1, flexFlow: "column wrap"}} justify="center" spacing={3}>
        
      {// @ts-ignore
      list.map( ({node:category})=> {
        
        return (
          <Grid key={category.id} item style={{flexGrow:1}}>
          <Paper elevation={3}>
            <div style={{padding:10}}>
            <Link to={`/category/${category.slug}`}> <h3 className="section-head">{category.title}</h3></Link>
            <div>{category.slug}</div>
            {/* <pre>{JSON.stringify(node.post, null, 2)}</pre> */}
            <CategoryDetail posts={category.post}/>
            </div>
          </Paper>
      </Grid>
      )})}
      </Grid>
  )
}

export default CategoryList;
