import React from 'react';
//import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { Link } from 'gatsby';

// @ts-ignore
const CategoryDetail =  ({ posts}) => {
    let postedCount: number = 0;

    let x = 
        <>
        {    
            posts && posts.map(
            // @ts-ignore
            ({title, excerpt, slug, isShown}, i)=> {
                if(isShown == false) return <></>;
                if(postedCount++ < 3 ) {
                    return (
                        <>
                        <Link to={`/post/${slug}`}><b>{title}</b></Link>
                        {excerpt && <div
  dangerouslySetInnerHTML={{
    __html: excerpt.childMarkdownRemark.html,
  }}
/>}
                        </>
                    )
                }
                else {
                    return (<></>)
                }
                
            
            })
         }
        </>;

    return postedCount > 0 ? x : <>Nothing written yet... come back soon.</>
    

}

export default CategoryDetail;

